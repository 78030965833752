import { Grid, Menu, MenuItem, Paper, TableContainer, IconButton } from "@mui/material";
import { AddButton, SearchField } from "@synapse/frontend-react";
import { observer } from "mobx-react";
import { useCallback, useEffect } from "react";
import { $anchorRefStore } from "../../../../../store/AnchorRef";
import { $contractsDataStore } from "../../../../../store/ContractsStore";
import { $loginUserStore } from "../../../../../store/ResponseData";
import EditNormativeData from "./EditNormativeData";
import Table from "../../../../ui/table/Table";
import { type MRT_ColumnDef } from "material-react-table";
import { formatDate } from "../../../../../utils/utils";
import { MoreHoriz } from "@mui/icons-material";
import { Normative } from "../../../../../types/normativeData";

const NormativeData = observer(() => {
    const { normPage, normRowsPerPage, normSort, contractDetail, normSearch, normList, normLoadingTable } =
        $contractsDataStore;

    useEffect(() => {
        $contractsDataStore.getNormList(
            String(contractDetail?.guid),
            String(normPage + 1),
            normSort.by,
            normSort.order,
            String(normRowsPerPage),
            normSearch
        );
    }, [contractDetail?.guid, normPage, normRowsPerPage, normSort.by, normSort.order, normSearch]);

    const columns: MRT_ColumnDef<Normative>[] = [
        {
            accessorKey: "id",
            header: "№ п/п",
            size: 100,
            enableSorting: false,
            Cell: ({ row }) => <div>{normRowsPerPage * normPage + row.index + 1}</div>,
        },
        {
            accessorKey: "period",
            accessorFn: (row) => formatDate(row.start, "d.m.y") + " - " + formatDate(row.end, "d.m.y"),
            header: "Период",
            size: 300,
            enableSorting: false,
        },
        {
            accessorKey: "plan_workers",
            header: "Норматив чел. план",
            size: 300,
            enableSorting: false,
        },
        {
            accessorKey: "hours_worker",
            header: "Норматив чел/час план",
            size: 300,
            enableSorting: false,
        },
        {
            accessorKey: "action",
            header: "Действия",
            size: 100,
            enableSorting: false,
            accessorFn: (row) => (
                <IconButton
                    onClick={(event) => {
                        $anchorRefStore.setAnchorRef(event.currentTarget);
                        $contractsDataStore.setCurrentNormGuid(row?.guid ?? "");
                    }}
                >
                    <MoreHoriz />
                </IconButton>
            ),
            enableHiding: $loginUserStore.isAdmin(),
        },
    ];

    const changeTableSort = useCallback(
        (key: string) => {
            if (key !== "status" && key !== "action") {
                $contractsDataStore.setNormSort({
                    by: key,
                    order: normSort.order === "asc" ? "desc" : "asc",
                });
            }
        },
        [normSort]
    );

    const handleChangePage = (v: number) => {
        $contractsDataStore.setNormPage(v);
    };

    const handleChangeRowsPerPage = (v: number) => {
        $contractsDataStore.setNormRowsPerPage(v);
        $contractsDataStore.setNormPage(0);
    };

    const handleEditNorm = () => {
        $contractsDataStore.setEditNorm(true);
    };

    return (
        <>
            <Grid container justifyContent={$loginUserStore.isAdmin() ? "space-between" : "flex-end"}>
                {$loginUserStore.isAdmin() ? (
                    <AddButton
                        onClick={handleEditNorm}
                        // disabled={contractDetail?.available_users?.items.length === undefined}
                        style={{
                            margin: 0,
                        }}
                    />
                ) : null}
                <SearchField
                    value={normSearch}
                    callback={(value) => $contractsDataStore.setNormSearch(value)}
                    callbackTimeout={700}
                    style={{
                        width: 300,
                    }}
                />
            </Grid>
            <TableContainer
                component={Paper}
                style={{
                    margin: "15px 0 0",
                }}
            >
                <Table
                    data={normList?.items}
                    columns={columns}
                    loading={normLoadingTable}
                    enableExpanding={false}
                    sortProps={normSort}
                    pageIndex={normPage}
                    pageSize={normRowsPerPage}
                    changeTableSort={changeTableSort}
                    handleChangePage={handleChangePage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                    rowCount={normList?.pagination.total! ?? 0}
                />
                {/* <Table>
                    <TableHead>
                        <TableRow>
                            {tableColumns.map((item: any) => {
                                if (!item.show) {
                                    return <></>;
                                }
                                return (
                                    <TableCell
                                        key={item.key}
                                        id={item.key}
                                        sortDirection={normSort.sort.by === item.key ? normSort.sort.order : false}
                                        style={{
                                            width: item.width,
                                            maxWidth: item.width,
                                        }}
                                    >
                                        <TableSortLabel
                                            active={normSort.sort.by === item.key}
                                            hideSortIcon={item.hideSortIcon}
                                            direction={normSort.sort.by === item.key ? normSort.sort.order : "asc"}
                                            onClick={() => {
                                                if (!item.hideSortIcon) {
                                                    changeTableSort(item.key);
                                                }
                                            }}
                                        >
                                            {item.label}
                                        </TableSortLabel>
                                    </TableCell>
                                );
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <NormativeDataTableLine />
                    </TableBody>
                </Table>
                <TablePagination
                    component="div"
                    count={$contractsDataStore.normList?.pagination.total! ?? 0}
                    page={normPage}
                    onPageChange={handleChangePage}
                    rowsPerPage={normRowsPerPage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    style={{ display: "flex" }}
                /> */}
            </TableContainer>
            <Menu
                disableScrollLock={true}
                anchorEl={$anchorRefStore.data}
                keepMounted
                open={Boolean($anchorRefStore.data)}
                onClose={() => {
                    $anchorRefStore.setAnchorRef(null);
                }}
            >
                <MenuItem
                    onClick={(event) => {
                        $anchorRefStore.setAnchorRef(null);
                        handleEditNorm();
                    }}
                >
                    Изменить
                </MenuItem>
            </Menu>
            {$contractsDataStore.editNorm && <EditNormativeData />}
        </>
    );
});
export default NormativeData;
