import { createTheme } from "@mui/material/styles";
import { ruRU } from "@mui/material/locale";

const theme = createTheme(
    {
        components: {
            MuiAutocomplete: {
                styleOverrides: {
                    root: {
                        width: "100%",
                        fontWeight: 600,
                        lineHeight: "22px",
                        letterSpacing: "0.5px",
                        fontSize: "16px",
                        color: "#1d1d1b",
                        textWrap: "nowrap",
                        "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                                borderColor: "#5ac8fa",
                                borderRadius: "12px",
                            },
                            "&:hover fieldset": {
                                borderColor: "#20a0ff",
                            },
                            "&.Mui-focused fieldset": {
                                borderColor: "#20a0ff",
                            },
                        },
                    },
                },
            },
            MuiTextField: {
                styleOverrides: {
                    root: {
                        ".MuiOutlinedInput-notchedOutline": {
                            border: "1px solid #5ac8fa",
                            borderRadius: "15px",
                        },
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                            borderColor: "#5ac8fa",
                        },
                        "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                                borderColor: "#5ac8fa",
                                borderRadius: "12px",
                            },
                            "&:hover fieldset": {
                                borderColor: "#20a0ff",
                            },
                            "&.Mui-focused fieldset": {
                                borderColor: "#20a0ff",
                            },
                        },
                    },
                },
            },
        },
    },
    ruRU
);

export { theme };
