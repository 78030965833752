import { makeAutoObservable } from "mobx";
import { Device, Devices, Columns } from "../types/device";
import { getDevicesList } from "../api-actions/DevicesApi";
import throttle from "lodash.throttle";
import { DEFAULT_DEVICE, DEFAULT_ROWS_PER_PAGE } from "../utils/const";
import { OrderItemType } from "../types/order.sort";

class DeviceTableStore {
    deviceData?: Devices;
    deviceSearch: string = "";
    loadingDeviceTable: boolean = true;
    devicePage: number = 0;
    deviceRowsPerPage: number = DEFAULT_ROWS_PER_PAGE;
    deviceSort: OrderItemType = {
        by: "created_at",
        order: "desc",
    };
    addNewDevice: boolean = false;
    editDevice: boolean = false;
    editDeviceData: Device = DEFAULT_DEVICE;
    tableColumns: Columns = []

    constructor() {
        makeAutoObservable(this);
    }

    setData(data: Devices) {
        this.deviceData = data;
    }

    setPage(page: number) {
        this.devicePage = page;
    }

    setRowsPerPage(rowsPerPage: number) {
        this.deviceRowsPerPage = rowsPerPage;
    }

    setSort(sort: OrderItemType) {
        this.deviceSort = sort;
    }

    setLoadingDeviceTable(isLoading: boolean) {
        this.loadingDeviceTable = isLoading;
    }

    setEditDevice(status: boolean) {
        this.editDevice = status;
        if (status === false) {
            this.setEditDeviceData(DEFAULT_DEVICE);
        }
    }

    setEditDeviceData(data: Device) {
        this.editDeviceData = data;
    }

    getData(page: string, orderBy: string, direction: string, pageSize: string, search: string) {
        this.setLoadingDeviceTable(true);
        const getDeviceListInfo = async () => {
            return await getDevicesList(page, orderBy, direction, pageSize, search);
        };
        const throttledGetDeviceList = throttle(getDeviceListInfo, 100);
        throttledGetDeviceList()
            ?.then((res) => {
                if (res) {
                    this.setData(res);
                }
            })
            .finally(() => this.setLoadingDeviceTable(false));
    }

    updateData() {
        this.getData(
            String(this.devicePage + 1),
            this.deviceSort.by,
            this.deviceSort.order,
            String(this.deviceRowsPerPage),
            this.deviceSearch
        );
    }

    setAddNewDevice(status: boolean) {
        this.addNewDevice = status;
    }

    setSearch(data: string) {
        this.devicePage = 0;
        this.deviceSearch = data;
    }

    setTableColumns(columns: Columns) {
        this.tableColumns = columns;
    }
}

export const $deviceTableDataStore = new DeviceTableStore();
