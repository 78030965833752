import { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { MaterialReactTable, type MRT_SortingState, useMaterialReactTable } from "material-react-table";
import { TableProps } from "../../../types/ui/table";
import { MRT_Localization_RU } from "material-react-table/locales/ru";
import { Circle } from "@mui/icons-material";

/**
 * columns - массив столбцов
 * -- accessorKey - название поля (напр. 'name')
 * -- header - Название поля для пользователя
 * -- size - ширина колонки
 * data - массив данных для заполнения таблицы строками
 * changeTableSort             - callback для изменения поля, по которому происходит сортировка (by) и направления сортировки (order)
 * pageIndex                   - Текущая страница (нумерация с 1)
 * pageSize                    - Строк на странице
 * rowCount                       - Сколько всего записей
 * handleChangePage            - callback при изменении страницы
 * handleChangeRowsPerPage     - callback кол-ва строк на странице
 * loading                     - Если данные запрашиваются (не готовы), передайте true, а когда будут готовы false. Это добавит анимацию загрузки
 * sortProps                   - объект со значениями поля и направления сортировки { by: 'name', order: 'desc'}
 * enableExpanding             - признак того, что данные для таблицы имеют несколько уровней и строки будут раскрываться
 * handleClickRow              - callback при клике на строку
 * handleDoubleClickRow        - callback при двойном клике на строку
 * @returns {JSX.Element}
 */

const Table = observer(
    ({
        data,
        columns,
        pageIndex,
        pageSize,
        rowCount,
        handleChangePage,
        handleChangeRowsPerPage,
        loading,
        changeTableSort,
        sortProps = {
            by: "",
            order: "asc",
        },
        enableExpanding = false,
        handleClickRow,
        handleDoubleClickRow,
    }: TableProps) => {
        const [pagination, setPagination] = useState({
            pageIndex: pageIndex ?? 1,
            pageSize: pageSize ?? 25,
        });

        const [sorting, setSorting] = useState<MRT_SortingState>([]);

        useEffect(() => {
            if (sorting.length && changeTableSort) {
                const by = sorting[0].id;
                const order = sorting[0].desc ? "desc" : "asc";
                changeTableSort(by, order);
            }
        }, [sorting]);

        useEffect(() => {
            if (handleChangePage) {
                handleChangePage(pagination.pageIndex);
            }
        }, [pagination.pageIndex]);

        useEffect(() => {
            if (handleChangeRowsPerPage) {
                handleChangeRowsPerPage(pagination.pageSize);
            }
        }, [pagination.pageSize]);

        const [columnVisibility, setColumnVisibility] = useState({});

        // Определяем колонки которые нужно скрыть
        useEffect(() => {
            const hiddenColumns = columns.reduce<Record<string, boolean>>((acc, column) => {
                if (column?.id && column?.enableHiding === false) {
                    acc[column.id] = false;
                }
                return acc;
            }, {});

            setColumnVisibility(hiddenColumns);
        }, [columns]);

        const table = useMaterialReactTable({
            muiTableBodyRowProps: ({ row, staticRowIndex, table }) => ({
                onClick: (event) => (handleClickRow ? handleClickRow(row.original) : null),
                onDoubleClick: (event) => (handleDoubleClickRow ? handleDoubleClickRow(row.original) : null),
                sx: handleClickRow || handleDoubleClickRow ? { cursor: "pointer" } : null,
            }),
            columns,
            data: data ?? [],
            enableColumnActions: false,
            enableColumnFilters: false,
            enablePagination: !!pageSize,
            enableTopToolbar: false,
            enableBottomToolbar: !!sortProps.by,
            manualPagination: !!pageSize,
            manualSorting: !!sortProps.by,
            enableSorting: !!sortProps.by,
            rowCount,
            localization: MRT_Localization_RU,
            enableExpanding,
            state: {
                columnVisibility,
                pagination,
                showSkeletons: loading,
                sorting: [
                    {
                        id: sortProps.by,
                        desc: sortProps?.order === "desc",
                    },
                ],
            },
            initialState: {
                sorting: [
                    {
                        id: sortProps?.by,
                        desc: sortProps?.order === "desc",
                    },
                ],
            },
            onColumnVisibilityChange: setColumnVisibility,
            getSubRows: (row) => row.items,
            onPaginationChange: setPagination,
            onSortingChange: setSorting,
            muiExpandButtonProps: ({ row }) => {
                if (row.depth === 3) {
                    const color = (() => {
                        switch (row.original.status) {
                            case "active":
                                return "lightgreen";
                            case "inactive":
                                return "#D7DA23";
                            default:
                                return "lightcoral";
                        }
                    })();

                    return {
                        sx: {
                            opacity: 1,
                            marginLeft: 8 * row.depth + "px",
                        },
                        children: (
                            <Circle
                                style={{
                                    fontSize: 10,
                                    color: color,
                                    marginRight: 10,
                                }}
                            />
                        ),
                    };
                }
                return {
                    sx: {
                        marginLeft: 8 * row.depth + "px",
                    },
                };
            },
            muiPaginationProps: {
                color: "secondary",
                rowsPerPageOptions: [10, 25, 50, 100],
                shape: "rounded",
                variant: "outlined",
            },
            displayColumnDefOptions: {
                "mrt-row-expand": {
                    size: 15,
                    muiTableBodyCellProps: {
                        size: "small",
                        padding: "none",
                        width: 30,
                    },
                    muiTableHeadCellProps: {
                        size: "small",
                        padding: "none",
                        align: "center",
                    },
                    Header: ({ table }) => {
                        return <></>;
                    },
                },
            },
            muiTablePaperProps: {
                elevation: 0,
                sx: {
                    borderRadius: "4px",
                    border: "1px solid #5ac8fa",
                    height: "100%",
                    overflowY: "auto",
                    overflowX: "hidden",
                    padding: "0",
                },
            },
            muiTableContainerProps: {
                sx: {
                    overflow: "hidden",
                },
            },
        });

        return <MaterialReactTable table={table} />;
    }
);

export default Table;
