import { Close } from "@mui/icons-material";
import {
    Checkbox,
    Dialog,
    DialogTitle,
    DialogActions,
    FormControlLabel,
    FormGroup,
    Grid,
    IconButton,
    TextField,
    Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { ButtonSquare } from "@synapse/frontend-react";
import { observer } from "mobx-react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { addContractHouseList } from "../../../../../api-actions/ContractsApi";
import { $contractsDataStore } from "../../../../../store/ContractsStore";
import { ContractAddress } from "../../../../../types/contractAddress";
import { Item } from "../../../../../types/ui/autocomplete";
import { getOrganisationName, getUKName } from "../../../../../utils/utils";
import AutocompleteMultiple from "../../../../ui/AutocompleteMultiple";
import dayjs from "dayjs";
import DatePicker from "../../../../ui/DatePicker";

const StyledDialog = styled(Dialog)({
    height: "auto",
    padding: "15px",
    margin: "0 auto",
});

const AddHouse = observer(() => {
    const [error, setError] = useState("");
    const [selectedHouses, setSelectedHouses] = useState<[] | Item[]>([]);
    const [isSelectedAll, setIsSelectedAll] = useState<boolean>(false);

    const {
        contractAddressPage,
        contractAddressRowsPerPage,
        contractAddressSort,
        contractDetail,
        contractAddressSearch,
    } = $contractsDataStore;

    const [form, setForm] = useState<ContractAddress>({
        guid: "",
        contract: contractDetail?.guid!,
        house: "",
        status: "WORK",
        name: "",
        zones: {
            items: [],
        },
        start: "",
        end: "",
    });

    const addresses = useMemo(() => {
        return contractDetail?.available_houses?.items.map((item) => {
            return {
                id: item.id,
                label: item.name,
            };
        });
    }, [contractDetail?.available_houses?.items]);

    useEffect(() => {
        if (selectedHouses) {
            setIsSelectedAll(addresses?.length === selectedHouses.length);
        }
    }, [selectedHouses, addresses]);

    const handleSelectedAddresses = () => {
        if (isSelectedAll) {
            setForm({ ...form, house: "" });
            setSelectedHouses([]);
        } else {
            if (addresses) {
                const strOfGuid = addresses.map((item) => item.id).join(",");
                setForm({ ...form, house: strOfGuid });
                setSelectedHouses(addresses);
            }
        }
    };

    const handleHousesSelect = useCallback((houses: Item[]) => {
        const strOfGuid = houses.map((item) => item.id).join(",");
        setForm((prevForm) => ({
            ...prevForm,
            house: strOfGuid,
        }));

        setSelectedHouses(houses);
    }, []);

    const handleSubmit = async () => {
        if (!form.start) {
            setError("Дата начала обязательна!");
            return;
        }

        $contractsDataStore.setLoading(true);
        await addContractHouseList(form).then((contracts) => {
            $contractsDataStore.getHouseList(
                String(contractDetail?.guid),
                String(contractAddressPage + 1),
                contractAddressSort.by,
                contractAddressSort.order,
                String(contractAddressRowsPerPage),
                contractAddressSearch
            );
            $contractsDataStore.getDetail(String(contractDetail?.guid));
            $contractsDataStore.setAddNewHouse(false);
        });
        $contractsDataStore.setAddressLoading(false);
    };

    const normalizeDate = (dateString: string) => {
        const normDate = dayjs(dateString).format("YYYY-MM-DD");
        return new Date(normDate).getTime();
    };

    useEffect(() => {
        let textError = "";

        if (contractDetail?.start && form.start) {
            const contractDateStart = normalizeDate(contractDetail?.start);
            const currentdate = normalizeDate(form.start);

            if (contractDateStart > currentdate) {
                textError =
                    "Дата не может быть раньше даты начала действия договора (" +
                    dayjs(contractDetail?.start).format("DD.MM.YYYY") +
                    ")";
            }
        }

        if (contractDetail?.end && form.start) {
            const contractDateEnd = normalizeDate(contractDetail?.end);
            const currentdate = normalizeDate(form.start);

            if (contractDateEnd < currentdate) {
                textError =
                    "Дата не может быть позже даты окончания действия договора (" +
                    dayjs(contractDetail?.end).format("DD.MM.YYYY") +
                    ")";
            }
        }

        setError(textError);
    }, [form.start]);

    const contractName = $contractsDataStore.getName(contractDetail);
    const contractFrom = contractDetail?.from ? getUKName(contractDetail?.from) : " ";
    const contractTo = contractDetail?.to ? getOrganisationName(contractDetail?.to) : " ";

    return (
        <StyledDialog
            open={$contractsDataStore.addNewHouse}
            PaperProps={{
                sx: {
                    borderRadius: "12px",
                    boxShadow: "0 1px 3px 0 rgb(0 0 0 / 20%)",
                    width: 692,
                    overflowY: "unset",
                },
            }}
            keepMounted={true}
            aria-labelledby="dialog-title"
            aria-describedby="dialog-description"
            maxWidth={false}
            scroll="body"
        >
            <IconButton
                aria-label="close"
                style={{
                    position: "absolute",
                    right: "-45px",
                    top: "-45px",
                    color: "white",
                }}
                onClick={() => $contractsDataStore.setAddNewHouse(false)}
            >
                <Close fontSize="large" />
            </IconButton>
            <DialogTitle
                style={{
                    textAlign: "center",
                }}
            >
                <Typography
                    style={{
                        fontSize: "34px",
                        lineHeight: "36px",
                        letterSpacing: "0",
                        fontWeight: "600",
                        color: "#20a0ff",
                    }}
                >
                    Добавить дом к договору
                </Typography>
            </DialogTitle>
            <Grid px={"90px"}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            label="Управляющая компания"
                            InputProps={{
                                readOnly: true,
                            }}
                            size="medium"
                            variant="outlined"
                            fullWidth
                            value={contractFrom}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="Организация"
                            InputProps={{
                                readOnly: true,
                            }}
                            size="medium"
                            variant="outlined"
                            fullWidth
                            value={contractTo}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="Номер договора"
                            InputProps={{
                                readOnly: true,
                            }}
                            size="medium"
                            variant="outlined"
                            fullWidth
                            value={contractName}
                        />
                    </Grid>
                    <Grid item container xs={12} justifyContent={"flex-end"}>
                        <DatePicker
                            value={form.start ? dayjs(form.start) : null}
                            label={"Дата начала"}
                            slotProps={{
                                textField: {
                                    clearable: true,
                                    error: !!error,
                                    helperText: error,
                                    sx: {
                                        width: "208px",
                                    },
                                },
                            }}
                            onChange={(value) => {
                                const newDate =
                                    value && dayjs(value).isValid() ? dayjs(value).format("YYYY-MM-DD") : "";
                                setForm({ ...form, start: newDate });
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <AutocompleteMultiple
                            noOptionsText={"Адрес не найден"}
                            id="user"
                            value={selectedHouses}
                            options={addresses !== undefined ? addresses : []}
                            getOptionLabel={(option: any) => option.label}
                            label="Адрес"
                            onChange={(e, v) => {
                                handleHousesSelect(v as Item[]);
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormGroup>
                            <FormControlLabel
                                control={<Checkbox checked={isSelectedAll} onChange={handleSelectedAddresses} />}
                                label={isSelectedAll ? "Снять все" : "Выбрать все"}
                            />
                        </FormGroup>
                    </Grid>
                </Grid>
            </Grid>
            <DialogActions>
                <ButtonSquare
                    disabled={!!error}
                    variant="contained"
                    color="primary"
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        margin: "15px auto",
                    }}
                    onClick={handleSubmit}
                >
                    Добавить
                </ButtonSquare>
            </DialogActions>
        </StyledDialog>
    );
});
export default AddHouse;
