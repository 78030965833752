import {
    ArrowBackIosNew,
    ArrowForwardIos,
    Directions,
    DirectionsOff,
    Mic,
    MicOff,
    Visibility,
    VisibilityOff,
} from "@mui/icons-material";
import TuneIcon from "@mui/icons-material/Tune";
import { Box, Button, ButtonGroup, CircularProgress, Grid } from "@mui/material";
import { styled } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import DatePicker from "../../ui/DatePicker";
import dayjs from "dayjs";
import { observer } from "mobx-react";
import { useCallback, useState, useEffect } from "react";
import { MapContainer, TileLayer } from "react-leaflet";
import { $monitoringStore } from "../../../store/MonitoringStore";
import { $loginUserStore } from "../../../store/ResponseData";
import { MonitoringItem } from "../../../types/monitoring";
import MonitoringMap from "./MonitoringMap";
import UserInfo from "./UserInfo";
import AutocompleteMultiple from "../../ui/AutocompleteMultiple";
import { ButtonSquare } from "@synapse/frontend-react";

const StyledMapContainer = styled(MapContainer)((props) => ({
    height: "100vh",
    width: "100%",

    [props.theme.breakpoints.up("md")]: {
        position: "absolute",
        top: 0,
        bottom: 0,
    },
}));

const Monitoringv3 = observer(() => {
    const { filters, initialFilters, usersDrawerIsOpen, mapCenter, loadingUsersData, selectedOptions, disableChange } =
        $monitoringStore;
    const [showFilter, setShowFilter] = useState(false);
    const [applyBtnDisable, setApplyBtnDisable] = useState(false);
    const [clearBtnDisable, setCleadBtnDisable] = useState(true);
    const today = new Date();
    const yesterday = new Date(today);
    // yesterday.setDate(yesterday.getDate() - 1);

    // const cityListData = $monitoringStore.filterData.city?.items;
    const ukListData = $monitoringStore.filterData.uk?.items;
    const orgListData = $monitoringStore.filterData.organisation?.items;
    const contractListData = $monitoringStore.filterData.contract?.items;
    const userListData = $monitoringStore.filterData.user?.items;

    const getStoreData = (data: MonitoringItem[] = []) => {
        if (Array.isArray(data)) {
            return data.slice().sort((a, b) => a?.name.localeCompare(b?.name));
        } else {
            return [];
        }
    };

    // const cityList = getStoreData(cityListData);
    const ukList = getStoreData(ukListData);
    const orgList = getStoreData(orgListData);
    const contractList = getStoreData(contractListData);
    const userList = getStoreData(userListData);

    useEffect(() => {
        $monitoringStore.getFilterInfo();
        const keys = Object.keys(initialFilters);
        type FilterKeys = keyof typeof initialFilters;
        let diff = false;

        keys.forEach((key) => {
            //Явное указание типа ключа, чтобы TS не ругался.
            // TS не разрешает использовать строковые индексы,
            // если объект не объявлен с индексным сигнатурой или если тип индекса не указан явно.
            const typedKey = key as FilterKeys;

            if (initialFilters[typedKey] !== filters[typedKey]) {
                diff = true;
            }
        });

        if (diff) {
            setCleadBtnDisable(false);
            setApplyBtnDisable(false);
        } else {
            setCleadBtnDisable(true);
            setApplyBtnDisable(true);
        }
    }, [filters]);

    const handleFilterChangeDate = useCallback((value: string) => {
        if (value === undefined) {
            value = "";
        }
        $monitoringStore.setFilterDate(value);
    }, []);

    const handleFilterChangeOptions = useCallback((name: string, arrayItems: MonitoringItem[]) => {
        const strOfGuid = arrayItems.map((item) => item.id).join(",");
        $monitoringStore.setFilterOption(name, strOfGuid);
        $monitoringStore.setSelectedOption(name, arrayItems);
    }, []);

    return (
        <div>
            <StyledMapContainer zoom={17} center={[mapCenter[1], mapCenter[0]]}>
                <Box
                    style={{
                        position: "absolute",
                        zIndex: 999,
                        left: "calc(50vw - 50px)",
                        top: "calc(50vh - 50px)",
                        display: loadingUsersData ? "block" : "none",
                    }}
                >
                    <CircularProgress disableShrink />
                </Box>
                <Grid
                    container
                    direction={"column"}
                    pt={2}
                    pl={2}
                    spacing={2}
                    style={{
                        position: "absolute",
                        zIndex: 999,
                    }}
                >
                    <Grid item>
                        <ButtonGroup
                            variant="outlined"
                            style={{
                                background: "white",
                                borderRadius: "12px",
                            }}
                        >
                            <Tooltip
                                arrow
                                slotProps={{
                                    popper: {
                                        style: {
                                            width: "fit-content",
                                            whiteSpace: "pre-line",
                                            textAlign: "center",
                                        },
                                    },
                                }}
                                title={filters.show_zones === 1 ? "Скрыть ГЕО-зоны" : "Показать ГЕО-зоны"}
                            >
                                <Button
                                    variant="outlined"
                                    onClick={() => $monitoringStore.toggleShowZones()}
                                    style={{
                                        borderRadius: "12px 0 0 12px",
                                    }}
                                >
                                    {filters.show_zones === 1 ? <Visibility /> : <VisibilityOff />}
                                </Button>
                            </Tooltip>
                            <Tooltip
                                arrow
                                slotProps={{
                                    popper: {
                                        style: {
                                            width: "fit-content",
                                            whiteSpace: "pre-line",
                                            textAlign: "center",
                                        },
                                    },
                                }}
                                title={
                                    filters.user.length <= 0
                                        ? "Маршруты доступны при выборе\r\nсотрудников в фильтре"
                                        : filters.show_zones === 1
                                        ? "Скрыть маршруты"
                                        : "Показать маршруты"
                                }
                            >
                                <Button
                                    variant="outlined"
                                    onClick={() => $monitoringStore.toggleShowRoutes()}
                                    disabled={filters.user.length <= 0}
                                    style={{
                                        pointerEvents: "auto",
                                        cursor: filters.user.length <= 0 ? "not-allowed" : "pointer",
                                    }}
                                >
                                    {filters.show_routes === 1 ? <Directions /> : <DirectionsOff />}
                                </Button>
                            </Tooltip>
                            {!$loginUserStore.isAdmin() ? null : (
                                <Tooltip
                                    arrow
                                    slotProps={{
                                        popper: {
                                            style: {
                                                width: "fit-content",
                                                whiteSpace: "pre-line",
                                                textAlign: "center",
                                            },
                                        },
                                    }}
                                    title={
                                        filters.user.length <= 0
                                            ? "Фильтр доступен при выборе\r\nсотрудников в фильтре"
                                            : filters.noise_filter === 1
                                            ? "Отменить фильтр шумов"
                                            : "Добавить фильтр шумов"
                                    }
                                >
                                    <Button
                                        variant="outlined"
                                        disabled={filters.user.length <= 0}
                                        onClick={() => $monitoringStore.toggleNoiseFilter()}
                                        style={{
                                            pointerEvents: "auto",
                                            cursor: filters.user.length <= 0 ? "not-allowed" : "pointer",
                                        }}
                                    >
                                        {filters.noise_filter === 1 ? <Mic /> : <MicOff />}
                                    </Button>
                                </Tooltip>
                            )}
                            <Tooltip
                                arrow
                                slotProps={{
                                    popper: {
                                        style: {
                                            width: "fit-content",
                                            whiteSpace: "pre-line",
                                            textAlign: "center",
                                        },
                                    },
                                }}
                                title={usersDrawerIsOpen ? "Скрыть панель сотрудников" : "Показать панель сотрудников"}
                            >
                                <Button
                                    variant="outlined"
                                    onClick={() => $monitoringStore.toggleDrawer()}
                                    style={{
                                        borderRadius: "0 12px 12px 0",
                                    }}
                                >
                                    {usersDrawerIsOpen ? <ArrowForwardIos /> : <ArrowBackIosNew />}
                                </Button>
                            </Tooltip>
                        </ButtonGroup>
                    </Grid>
                    <Grid item>
                        <ButtonGroup
                            variant="outlined"
                            style={{
                                background: "white",
                                borderRadius: "12px",
                            }}
                        >
                            <DatePicker
                                minDate={"2024-07-01"}
                                maxDate={yesterday}
                                defaultValue={dayjs()}
                                slotProps={{
                                    textField: {
                                        clearable: false,
                                        sx: {
                                            width: "150px",
                                            "& .MuiOutlinedInput-root": {
                                                "& fieldset": {
                                                    borderRadius: "12px 0 0 12px !important",
                                                },
                                            },
                                        },
                                    },
                                    popper: {
                                        style: {
                                            zIndex: 999,
                                        },
                                    },
                                }}
                                onChange={(value: any) =>
                                    handleFilterChangeDate(value ? value.format("YYYY-MM-DD") : "")
                                }
                            />
                            <Tooltip arrow title={showFilter ? "Скрыть фильтры" : "Показать фильтры"}>
                                <Button
                                    variant="outlined"
                                    onClick={() => {
                                        setShowFilter(!showFilter);
                                    }}
                                    style={{
                                        borderRadius: "0 12px 12px 0",
                                    }}
                                >
                                    <TuneIcon />
                                </Button>
                            </Tooltip>
                        </ButtonGroup>
                    </Grid>
                    <Grid item>
                        <Grid
                            container
                            direction="column"
                            style={{
                                minWidth: "360px",
                                width: "fit-content",
                                background: "white",
                                borderRadius: "12px",
                                padding: "15px",
                                display: showFilter ? "flex" : "none",
                                margin: 0,
                            }}
                        >
                            {/* <Grid item>
                            <AutocompleteMultiple
                                noOptionsText={"Города отсутствуют"}
                                id="city"
                                options={cityList}
                                value={
                                    cityListData?.find(
                                        (item) => item.id === filters.city
                                    )?.id
                                }
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        label="Город"
                                    />
                                )}
                                onChange={(e, v) => {
                                    const guid = cityListData?.find(
                                        (item) => item.name === v
                                    )?.id;
                                    handleFiltersChange("city", guid);
                                }}
                            />
                        </Grid> */}
                            <Grid item mb={1}>
                                <AutocompleteMultiple
                                    disabled={disableChange.uk}
                                    noOptionsText={"УК отсутствуют"}
                                    id="uk"
                                    options={ukList}
                                    value={selectedOptions.uk}
                                    label="Управляющая компания"
                                    onChange={(e: any, v: any) => {
                                        handleFilterChangeOptions("uk", v as MonitoringItem[]);
                                    }}
                                    sx={{
                                        width: 350,
                                        "& .MuiOutlinedInput-root": {
                                            padding: "5px",
                                        },
                                        "& .MuiChip-root.Mui-disabled": {
                                            opacity: 1,
                                        },
                                    }}
                                />
                            </Grid>
                            <Grid item mb={1}>
                                <AutocompleteMultiple
                                    disabled={!filters.uk || disableChange.organisation}
                                    noOptionsText={"Организации отсутствуют"}
                                    id="organisation"
                                    value={selectedOptions.organisation}
                                    isOptionEqualToValue={(option: any, value: any) => option.id === value.id}
                                    options={orgList}
                                    label="Организация"
                                    onChange={(e, v) => {
                                        handleFilterChangeOptions("organisation", v as MonitoringItem[]);
                                    }}
                                    sx={{
                                        width: 350,
                                        "& .MuiOutlinedInput-root": {
                                            padding: "5px",
                                        },
                                        "& .MuiChip-root.Mui-disabled": {
                                            opacity: 1,
                                        },
                                    }}
                                />
                            </Grid>
                            <Grid item mb={1}>
                                <AutocompleteMultiple
                                    disabled={!filters.organisation || !filters.uk || disableChange.contract}
                                    noOptionsText={"Договоры отсутствуют"}
                                    id="contract"
                                    value={selectedOptions.contract}
                                    options={contractList}
                                    label="Договор"
                                    onChange={(e, v) => {
                                        handleFilterChangeOptions("contract", v as MonitoringItem[]);
                                    }}
                                    sx={{
                                        width: 350,
                                        "& .MuiOutlinedInput-root": {
                                            padding: "5px",
                                        },
                                        "& .MuiChip-root.Mui-disabled": {
                                            opacity: 1,
                                        },
                                    }}
                                />
                            </Grid>
                            <Grid item mb={1}>
                                <AutocompleteMultiple
                                    disabled={!filters.contract || !filters.organisation || !filters.uk}
                                    noOptionsText={"Сотрудники отсутствуют"}
                                    id="user"
                                    value={selectedOptions.user}
                                    options={userList}
                                    label="Сотрудник"
                                    onChange={(e, v) => {
                                        handleFilterChangeOptions("user", v as MonitoringItem[]);
                                    }}
                                    sx={{
                                        width: 350,
                                        "& .MuiOutlinedInput-root": {
                                            padding: "5px",
                                        },
                                        "& .MuiChip-root.Mui-disabled": {
                                            opacity: 1,
                                        },
                                    }}
                                />
                            </Grid>
                            <Grid item container direction={"row"} justifyContent={"space-between"}>
                                <Grid item>
                                    <ButtonSquare
                                        variant="contained"
                                        disabled={applyBtnDisable}
                                        style={{ margin: 0 }}
                                        onClick={() => {
                                            $monitoringStore.updateMonitoringData();
                                            setApplyBtnDisable(true);
                                        }}
                                    >
                                        Применить
                                    </ButtonSquare>
                                </Grid>
                                <Grid item>
                                    <ButtonSquare
                                        variant="outlined"
                                        disabled={clearBtnDisable}
                                        onClick={() => {
                                            $monitoringStore.clearSelectedOption();
                                        }}
                                    >
                                        Сбросить
                                    </ButtonSquare>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <MonitoringMap />
                <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
            </StyledMapContainer>
            <UserInfo />
        </div>
    );
});

export default Monitoringv3;
