import Grid from "@mui/material/Grid";
import {
    Box,
    Divider,
    IconButton,
    List,
    ListItem,
    ListItemText,
    Typography,
    Avatar,
    Menu,
    Tooltip
} from "@mui/material";
import logo from "./icons/logo.png";
import OnCollapseMenu from "./icons/OnCollapseMenu";
import OnExpandMenu from "./icons/OnExpandMenu";
import Logout from "./icons/Logout";
import { $loginUserStore } from "../store/ResponseData";
import { observer } from "mobx-react";
import { styled } from "@mui/material/styles";
import { Link, NavLink, useLocation } from "react-router-dom";
import React, { useCallback } from "react";
import { getIcon, mockMenu } from "../utils/utils";
import { $mainMenuStore } from "../store/MenuStore";
import { AppRoute } from "../utils/const";
import { $zoneTableDataStore } from "../store/ZoneTableStore";
import { ArrowDropDown } from "@mui/icons-material";

const StyledMenu = styled(Box)({
    background: "white",
    padding: 0,
    height: "100%",
    boxShadow: "5px 0px 10px -3px rgba(0, 0, 0, 0.2)"
});

const StyledList = styled(List)({
    background: "white",
    padding: 0,
    height: "auto"
});

const StyledListItem = styled(ListItem)({
    backgroundColor: '"#fff"',
    paddingLeft: 0,
    paddingRight: 0,
    height: 55,
    "&:hover": {
        backgroundColor: "#eef1f6!important",
        color: "black"
    }
});

const StyledListItemText = styled(ListItemText)({
    height: 55,
    margin: 0,
    width: "100%",
    display: "flex",
    alignItems: "center",
    paddingLeft: 15,
    "& > span": {
        lineHeight: "16px"
    }
});

const StyledTypography = styled(Typography)({
    height: 55,
    "& > a": {
        color: "#20a0ff",
        textDecoration: "none",
        "&:hover": {
            color: "#fff"
        },
        "&.active > .MuiListItem-root": {
            backgroundColor: "#20a0ff!important",
            color: "#fff"
        }
    }
});

const MainMenu = observer(() => {
    const location = useLocation();
    const { menuStatus } = $mainMenuStore;

    const onToggleMenuButton = useCallback(() => {
        $mainMenuStore.setMenuStatus(!menuStatus);
    }, [menuStatus]);

    const handleClick = useCallback((id: number, name: string) => {
        $zoneTableDataStore.setShowZoneDetailes(false);
    }, []);

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClickAvatar = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <StyledMenu>
            <Grid
                container
                mt={0}
                direction={"column"}
                alignItems={menuStatus ? "flex-start" : "center"}
                style={{
                    position: "sticky",
                    top: 0
                }}
            >
                <Grid
                    container
                    item
                    direction={"row"}
                    justifyContent={menuStatus ? "space-around" : "center"}
                    alignItems={"center"}
                    sx={{
                        height: "70px"
                    }}
                >
                    <Grid item>
                        {!menuStatus ? null : (
                            <Link to={AppRoute.Root}>
                                <img src={logo} alt="ОКОЛО" />
                            </Link>
                        )}
                    </Grid>
                    <Grid item>
                        <IconButton onClick={onToggleMenuButton}>
                            {menuStatus ? <OnCollapseMenu /> : <OnExpandMenu />}
                        </IconButton>
                    </Grid>
                </Grid>
                {!menuStatus ? null : <Divider flexItem />}
                <Grid container item py={2}>
                    <Grid
                        container
                        item
                        direction="row"
                        justifyContent={menuStatus ? "left" : "center"}
                        alignItems="center"
                        flexWrap="nowrap"
                    >
                        <Grid item style={{ marginLeft: menuStatus ? 10 : 0 }}>
                            <Tooltip title="Настройки аккаунта">
                                <IconButton onClick={handleClickAvatar} size="small">
                                    <Avatar
                                        sx={{
                                            display: "flex",
                                            width: "60px",
                                            height: "60px",
                                            border: "1px solid #5ac8fa",
                                            borderRadius: "50%",
                                            color: "black",
                                            fontWeight: "500",
                                            fontSize: "0.875rem",
                                            backgroundColor: "white"
                                        }}
                                    >
                                        {$loginUserStore.data?.name
                                            .split(" ")
                                            .map(function (item) {
                                                return item[0];
                                            })
                                            ?.join("")
                                            .toUpperCase()}
                                    </Avatar>
                                </IconButton>
                            </Tooltip>
                        </Grid>
                        {menuStatus ? (
                            <Grid item style={{ marginLeft: 10 }}>
                                <p
                                    style={{
                                        margin: 0,
                                        fontSize: "14px",
                                        fontWeight: "bold"
                                    }}
                                >
                                    {$loginUserStore.data?.name}
                                </p>
                                <p style={{ margin: 0, fontSize: "14px" }}>
                                    {$loginUserStore.data?.organisation?.name}
                                </p>
                                {$loginUserStore.data?.roles.items.length ? (
                                    <p style={{ margin: 0, fontSize: "14px" }}>
                                        {"Роль: " +
                                            $loginUserStore.data?.roles.items
                                                .map((role) => role.name)
                                                .join(", ")}
                                    </p>
                                ) : null}
                                <p style={{ margin: 0, fontSize: "14px" }}>
                                    ID: {$loginUserStore.data?.id}
                                </p>
                            </Grid>
                        ) : null}
                    </Grid>
                </Grid>
                <Menu
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    onClick={handleClose}
                    PaperProps={{
                        elevation: 0,
                        sx: {
                            overflow: "visible",
                            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                            mt: 1.5,
                            "& .MuiAvatar-root": {
                                width: 40,
                                height: 40
                            },
                            "&:before": {
                                content: '""',
                                display: "block",
                                position: "absolute",
                                top: 0,
                                left: 22,
                                width: 12,
                                height: 12,
                                bgcolor: "background.paper",
                                transform: "translateY(-50%) rotate(45deg)",
                                zIndex: 0
                            }
                        }
                    }}
                    transformOrigin={{ horizontal: "right", vertical: "top" }}
                    anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                >
                    <ListItem>
                        <Avatar
                            sx={{
                                display: "flex",
                                width: "40px",
                                height: "40px",
                                border: "1px solid #5ac8fa",
                                borderRadius: "50%",
                                color: "black",
                                fontWeight: "500",
                                fontSize: "0.875rem",
                                backgroundColor: "white",
                                marginRight: "10px"
                            }}
                        >
                            {$loginUserStore.data?.name
                                .split(" ")
                                .map(function (item) {
                                    return item[0];
                                })
                                ?.join("")
                                .toUpperCase()}
                        </Avatar>
                        {$loginUserStore.data?.name}
                    </ListItem>
                    <Divider />
                    <ListItem
                        onClick={() => {
                            $loginUserStore.logout();
                        }}
                        sx={{ cursor: "pointer" }}
                    >
                        <Grid
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                "& .MuiButtonBase-root": {
                                    transition:
                                        "background-color, opacity 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                                    opacity: ".5",
                                    "&:hover": {
                                        opacity: "1"
                                    }
                                },
                                "& svg": {
                                    "& path": {
                                        stroke: "#20a0ff",
                                        strokeWidth: "1.5",
                                        strokeLinecap: "round",
                                        strokeLinejoin: "round",
                                        strokeMiterlimit: "10"
                                    }
                                }
                            }}
                        >
                            <IconButton
                                color="primary"
                                aria-label="logout"
                                size="medium"
                                style={{ marginRight: 10 }}
                            >
                                <Logout />
                            </IconButton>
                        </Grid>
                        <ListItemText primary="Выйти" />
                    </ListItem>
                </Menu>
                {!menuStatus ? null : <Divider flexItem />}
                <Grid
                    item
                    style={{
                        width: "100%"
                    }}
                >
                    <StyledList>
                        {mockMenu?.map((mockMenu, index) => {
                            return (
                                <React.Fragment key={"menu-item-" + index}>
                                    <StyledTypography variant="body1" key={"li-" + index}>
                                        <NavLink
                                            style={{
                                                display: "flex",
                                                margin: "15px auto",
                                                width: "100%"
                                            }}
                                            className={
                                                location.pathname === mockMenu.url ? "active" : ""
                                            }
                                            to={mockMenu.url}
                                            onClick={() => handleClick(mockMenu.id, mockMenu.name)}
                                        >
                                            <StyledListItem
                                                key={"menu-row-" + index}
                                                style={{
                                                    justifyContent: "center"
                                                }}
                                            >
                                                <IconButton
                                                    sx={{
                                                        "&:hover, &.Mui-focusVisible": {
                                                            background: "none"
                                                        },
                                                        marginLeft: menuStatus ? "10px" : 0
                                                    }}
                                                >
                                                    {getIcon(mockMenu.icon)}
                                                </IconButton>
                                                {menuStatus && (
                                                    <>
                                                        <StyledListItemText
                                                            primary={mockMenu.name}
                                                        />
                                                        {mockMenu.collapsed && (
                                                            <IconButton
                                                                sx={{
                                                                    "&:hover, &.Mui-focusVisible": {
                                                                        background: "none"
                                                                    }
                                                                }}
                                                            >
                                                                <ArrowDropDown />
                                                            </IconButton>
                                                        )}
                                                    </>
                                                )}
                                            </StyledListItem>
                                        </NavLink>
                                    </StyledTypography>
                                </React.Fragment>
                            );
                        })}
                    </StyledList>
                </Grid>
            </Grid>
        </StyledMenu>
    );
});

export default MainMenu;
