import { Grid, Menu, MenuItem, Paper, TableContainer, IconButton } from "@mui/material";
import { SearchField } from "@synapse/frontend-react";
import { observer } from "mobx-react";
import { useCallback, useEffect } from "react";
import { StyledCard } from "../../../App";
import AttachUkDialog from "./AttachUkDialog";
import EditRoleDialog from "./EditRoleDialog";
import { $anchorRefStore } from "../../../store/AnchorRef";
import { $membersDataStore } from "../../../store/MembersStore";
import { $loginUserStore } from "../../../store/ResponseData";
import MainContentWrap from "../../ui/wrappers/MainContentWrap";
import Table from "../../ui/table/Table";
import { type MRT_ColumnDef } from "material-react-table";
import { MoreHoriz } from "@mui/icons-material";
import { Member } from "../../../types/member";

const Members = observer(() => {
    const {
        membersPage,
        membersRowsPerPage,
        membersloadingTable,
        membersSort,
        membersSearch,
        membersData,
        organisation,
        memberData,
    } = $membersDataStore;

    const columns: MRT_ColumnDef<Member>[] = [
        {
            accessorKey: "name",
            header: "ФИО сотрудника",
            size: 300,
        },
        {
            accessorKey: "organisation.name",
            header: "Организация",
            size: 300,
        },
        {
            id: "roles",
            accessorFn: (row) => row.roles?.items.map((role) => role.name).join(", "),
            header: "Роль",
            size: 100,
            enableHiding: $loginUserStore.isAdmin(),
        },
        {
            accessorKey: "action",
            header: "Действия",
            size: 60,
            enableSorting: false,
            enableHiding: $loginUserStore.isAdmin(),
            accessorFn: (row) => (
                <IconButton
                    onClick={(event) => {
                        $anchorRefStore.setAnchorRef(event.currentTarget);
                        $membersDataStore.setMemberData(row);
                    }}
                >
                    <MoreHoriz />
                </IconButton>
            ),
        },
    ];

    useEffect(() => {
        $membersDataStore.getRoleList();
    }, []);

    useEffect(() => {
        $membersDataStore.getData(
            String(membersPage + 1),
            membersSort.by,
            membersSort.order,
            String(membersRowsPerPage),
            membersSearch,
            organisation
        );
    }, [membersSearch, membersPage, membersRowsPerPage, membersSort, organisation]);

    const changeTableSort = useCallback(
        (key: string) => {
            $membersDataStore.setSort({
                by: key,
                order: membersSort.by !== key ? "asc" : membersSort.order === "asc" ? "desc" : "asc",
            });
        },
        [membersSort]
    );

    const handleChangePage = (newPage: number) => {
        $membersDataStore.setPage(newPage);
    };

    const handleChangeRowsPerPage = (v: number) => {
        $membersDataStore.setRowsPerPage(v);
        $membersDataStore.setPage(0);
    };

    return (
        <MainContentWrap>
            <>
                <Grid
                    container
                    direction={"row"}
                    justifyContent={"space-between"}
                    style={{
                        paddingBottom: "1em",
                        paddingLeft: ".75em",
                    }}
                >
                    <Grid item>
                        <Grid container direction={"row"} spacing={2} alignItems={"baseline"}>
                            <Grid item>
                                <h3>Сотрудники</h3>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <SearchField
                            value={membersSearch}
                            callback={(value) => $membersDataStore.setSearch(value)}
                            callbackTimeout={700}
                            style={{
                                width: 300,
                            }}
                        />
                    </Grid>
                </Grid>
                <StyledCard>
                    <TableContainer component={Paper}>
                        <Table
                            data={membersData?.items}
                            columns={columns}
                            loading={membersloadingTable}
                            enableExpanding={false}
                            sortProps={membersSort}
                            pageIndex={membersPage}
                            pageSize={membersRowsPerPage}
                            changeTableSort={changeTableSort}
                            handleChangePage={handleChangePage}
                            handleChangeRowsPerPage={handleChangeRowsPerPage}
                            rowCount={membersData?.pagination.total! ?? 0}
                        />
                    </TableContainer>
                    <Menu
                        disableScrollLock={true}
                        anchorEl={$anchorRefStore.data}
                        keepMounted
                        open={Boolean($anchorRefStore.data)}
                        onClose={() => {
                            $anchorRefStore.setAnchorRef(null);
                        }}
                    >
                        {$loginUserStore.isAdmin() ? (
                            <MenuItem
                                onClick={(event) => {
                                    $anchorRefStore.setAnchorRef(null);
                                    $membersDataStore.setOnEditRoleDialog(true);
                                }}
                            >
                                Редактировать права
                            </MenuItem>
                        ) : null}
                        {$loginUserStore.isAdmin() &&
                        memberData.roles?.items.find((el) => el.name === "Руководитель региона") ? (
                            <MenuItem
                                onClick={(event) => {
                                    $anchorRefStore.setAnchorRef(null);
                                    $membersDataStore.setOnAttachUkDialog(true);
                                }}
                            >
                                Прикрепить УК
                            </MenuItem>
                        ) : null}
                    </Menu>
                    {$membersDataStore.onEditRoleDialog && <EditRoleDialog />}
                    {$membersDataStore.onAttachUkDialog && <AttachUkDialog />}
                </StyledCard>
            </>
        </MainContentWrap>
    );
});
export default Members;
