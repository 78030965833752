import { Close } from "@mui/icons-material";
import { Dialog, DialogTitle, Grid, IconButton, Typography, TextField } from "@mui/material";
import { styled } from "@mui/material/styles";
import { ButtonSquare } from "@synapse/frontend-react";
import { observer } from "mobx-react";
import { useCallback, useState } from "react";
import { saveAttachedUk } from "../../../api-actions/MembersApi";
import { $membersDataStore } from "../../../store/MembersStore";
import { $ukStore } from "../../../store/UkStore";
import { Organisation } from "../../../types/organisation";
import Notification from "../../ui/Notification";
import CustomTooltip from "../../ui/Tooltip";
import AutocompleteMultiple from "../../ui/AutocompleteMultiple";
import { Item } from "../../../types/ui/autocomplete";

const StyledDialog = styled(Dialog)({
    height: "auto",
    padding: "15px",
    margin: "0 auto",
});

const AttachUkDialog = observer(() => {
    const { memberData } = $membersDataStore;

    const ukList = $ukStore.data?.filter((item) => {
        return (
            item.guid !== memberData.organisation.guid &&
            !memberData.linkedUk?.items.some((el) => el.guid === item.guid)
        );
    });

    const [strUk, setStrUk] = useState("");
    const [selectedUk, setSelectedUk] = useState<[] | Item[]>(memberData.linkedUk ? memberData.linkedUk.items : []);

    const handleUKSelect = useCallback((ukList: Organisation[]) => {
        const strOfGuid = ukList.map((item) => item.guid).join(",");
        setStrUk(strOfGuid);
        setSelectedUk(ukList);
    }, []);

    const handleSubmit = async () => {
        $membersDataStore.setLoading(true);

        const ukInfo = {
            user: memberData.guid,
            uk: strUk,
        };
        await saveAttachedUk(ukInfo).then((resp) => {
            $membersDataStore.setOnAttachUkDialog(false);
            Notification({
                title: "Сохранено",
                type: "success",
                duration: 30000,
            });
        });
        $membersDataStore.setLoading(false);
        $membersDataStore.updateData();
    };

    return (
        <StyledDialog
            open={$membersDataStore.onAttachUkDialog}
            PaperProps={{
                sx: {
                    borderRadius: "12px",
                    boxShadow: "0 1px 3px 0 rgb(0 0 0 / 20%)",
                    width: 692,
                    overflowY: "unset",
                },
            }}
            keepMounted={true}
            aria-labelledby="dialog-title"
            aria-describedby="dialog-description"
            maxWidth={false}
            scroll="body"
        >
            <IconButton
                aria-label="close"
                style={{
                    position: "absolute",
                    right: "-45px",
                    top: "-45px",
                    color: "white",
                }}
                onClick={() => {
                    $membersDataStore.setOnAttachUkDialog(false);
                }}
            >
                <Close fontSize="large" />
            </IconButton>
            <Grid container justifyContent={"center"}>
                <Grid item xs={9}>
                    <Grid container direction={"column"} justifyContent={"center"} spacing={2}>
                        <Grid item xs={7}>
                            <DialogTitle
                                style={{
                                    textAlign: "center",
                                }}
                            >
                                <Grid container justifyContent={"center"} spacing={2}>
                                    <Grid item>
                                        <Typography
                                            variant={"h1"}
                                            style={{
                                                fontSize: "34px",
                                                lineHeight: "36px",
                                                letterSpacing: "0",
                                                fontWeight: "600",
                                                color: "#20a0ff",
                                                textWrap: "nowrap",
                                            }}
                                            component={"span"}
                                        >
                                            Присоединить УК
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant={"subtitle2"}>{"ФИО: " + memberData.name}</Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant={"subtitle2"}>
                                            {"Основная УК: " + memberData.organisation.name}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </DialogTitle>
                        </Grid>
                        <form
                            noValidate
                            style={{
                                paddingLeft: "18px",
                                maxWidth: "100%",
                            }}
                        >
                            <Grid item xs={12} style={{ marginBottom: "19px" }}>
                                <Grid
                                    container
                                    direction={"row"}
                                    alignItems={"center"}
                                    spacing={2}
                                    justifyContent={"space-between"}
                                >
                                    <Grid item xs={11}>
                                        <AutocompleteMultiple
                                            noOptionsText={"УК не найдены"}
                                            id="uk"
                                            value={selectedUk}
                                            options={ukList ?? []}
                                            getOptionLabel={(option: any) => option.name}
                                            label="УК"
                                            isOptionEqualToValue={(option: any, value: any) =>
                                                option.guid === value.guid
                                            }
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    variant="outlined"
                                                    placeholder="Выберите УК"
                                                    label="УК"
                                                    required
                                                />
                                            )}
                                            onChange={(e, v) => {
                                                handleUKSelect(v as Organisation[]);
                                            }}
                                            sx={{
                                                "& .MuiFormHelperText-root": {
                                                    margin: "3px 0 0",
                                                },
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={1}>
                                        <CustomTooltip tooltip={""} />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </form>
                        <Grid item xs={12} style={{ paddingTop: 0, paddingLeft: 0 }}>
                            <ButtonSquare
                                variant="contained"
                                color="primary"
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    margin: "15px auto",
                                }}
                                onClick={handleSubmit}
                            >
                                Сохранить
                            </ButtonSquare>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </StyledDialog>
    );
});
export default AttachUkDialog;
