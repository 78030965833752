import { Grid, Menu, MenuItem, Paper, TableContainer, IconButton } from "@mui/material";
import { AddButton, SearchField } from "@synapse/frontend-react";
import { observer } from "mobx-react";
import { useCallback, useEffect } from "react";
import { $anchorRefStore } from "../../../../../store/AnchorRef";
import { $contractsDataStore } from "../../../../../store/ContractsStore";
import { $loginUserStore } from "../../../../../store/ResponseData";
import EditCompletedWork from "./EditCompletedWork";
import Table from "../../../../ui/table/Table";
import { type MRT_ColumnDef } from "material-react-table";
import { formatDate } from "../../../../../utils/utils";
import { MoreHoriz } from "@mui/icons-material";
import { CompletedWork } from "../../../../../types/completedWorks";

const CompletedWorks = observer(() => {
    const {
        completedWorkPage,
        completedWorkRowsPerPage,
        completedWorkSort,
        contractDetail,
        completedWorkSearch,
        completedWorkList,
        completedWorkLoadingTable,
    } = $contractsDataStore;

    useEffect(() => {
        $contractsDataStore.getCompletedWorkList(
            String(contractDetail?.guid),
            String(completedWorkPage + 1),
            completedWorkSort.by,
            completedWorkSort.order,
            String(completedWorkRowsPerPage),
            completedWorkSearch
        );
    }, [
        contractDetail?.guid,
        completedWorkPage,
        completedWorkRowsPerPage,
        completedWorkSort.by,
        completedWorkSort.order,
        completedWorkSearch,
    ]);

    const columns: MRT_ColumnDef<CompletedWork>[] = [
        {
            accessorKey: "id",
            header: "№ п/п",
            size: 100,
            enableSorting: false,
            Cell: ({ row }) => <div>{completedWorkRowsPerPage * completedWorkPage + row.index + 1}</div>,
        },
        {
            accessorKey: "period",
            accessorFn: (row) => formatDate(row.start, "d.m.y") + " - " + formatDate(row.end, "d.m.y"),
            header: "Период",
            size: 300,
            enableSorting: false,
        },
        {
            accessorKey: "planned_cost",
            header: "Стоимость план (договор)",
            size: 300,
            enableSorting: false,
        },
        {
            accessorKey: "action",
            header: "Действия",
            size: 100,
            enableSorting: false,
            accessorFn: (row) => (
                <IconButton
                    onClick={(event) => {
                        $anchorRefStore.setAnchorRef(event.currentTarget);
                        $contractsDataStore.setCurrentCompletedWorkGuid(row?.guid ?? "");
                    }}
                >
                    <MoreHoriz />
                </IconButton>
            ),
            enableHiding: $loginUserStore.isAdmin(),
        },
    ];

    const changeTableSort = useCallback(
        (key: string) => {
            if (key !== "status" && key !== "action") {
                $contractsDataStore.setCompletedWorkSort({
                    by: key,
                    order: completedWorkSort.order === "asc" ? "desc" : "asc",
                });
            }
        },
        [completedWorkSort]
    );

    const handleChangePage = (v: number) => {
        $contractsDataStore.setCompletedWorkPage(v);
    };

    const handleChangeRowsPerPage = (v: number) => {
        $contractsDataStore.setCompletedWorkRowsPerPage(v);
        $contractsDataStore.setCompletedWorkPage(0);
    };

    const handleEditCompletedWork = () => {
        $contractsDataStore.setEditCompletedWork(true);
    };

    return (
        <>
            <Grid container justifyContent={$loginUserStore.isAdmin() ? "space-between" : "flex-end"}>
                {$loginUserStore.isAdmin() ? (
                    <AddButton
                        onClick={handleEditCompletedWork}
                        // disabled={contractDetail?.available_users?.items.length === undefined}
                        style={{
                            margin: 0,
                        }}
                    />
                ) : null}
                <SearchField
                    value={completedWorkSearch}
                    callback={(value) => $contractsDataStore.setCompletedWorkSearch(value)}
                    callbackTimeout={700}
                    style={{
                        width: 300,
                    }}
                />
            </Grid>
            <TableContainer
                component={Paper}
                style={{
                    margin: "15px 0 0",
                }}
            >
                <Table
                    data={completedWorkList?.items}
                    columns={columns}
                    loading={completedWorkLoadingTable}
                    enableExpanding={false}
                    sortProps={completedWorkSort}
                    pageIndex={completedWorkPage}
                    pageSize={completedWorkRowsPerPage}
                    changeTableSort={changeTableSort}
                    handleChangePage={handleChangePage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                    rowCount={completedWorkList?.pagination.total! ?? 0}
                />
                {/* <Table>
                    <TableHead>
                        <TableRow>
                            {tableColumns.map((item: any) => {
                                if (!item.show) {
                                    return <></>;
                                }
                                return (
                                    <TableCell
                                        key={item.key}
                                        id={item.key}
                                        sortDirection={
                                            completedWorkSort.sort.by === item.key
                                                ? completedWorkSort.sort.order
                                                : false
                                        }
                                        style={{
                                            width: item.width,
                                            maxWidth: item.width,
                                        }}
                                    >
                                        <TableSortLabel
                                            active={completedWorkSort.sort.by === item.key}
                                            hideSortIcon={item.hideSortIcon}
                                            direction={
                                                completedWorkSort.sort.by === item.key
                                                    ? completedWorkSort.sort.order
                                                    : "asc"
                                            }
                                            onClick={() => {
                                                if (!item.hideSortIcon) {
                                                    changeTableSort(item.key);
                                                }
                                            }}
                                        >
                                            {item.label}
                                        </TableSortLabel>
                                    </TableCell>
                                );
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <CompletedWorksTableLine />
                    </TableBody>
                </Table>
                <TablePagination
                    component="div"
                    count={$contractsDataStore.completedWorkList?.pagination.total! ?? 0}
                    page={completedWorkPage}
                    onPageChange={handleChangePage}
                    rowsPerPage={completedWorkRowsPerPage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    style={{ display: "flex" }}
                /> */}
            </TableContainer>
            <Menu
                disableScrollLock={true}
                anchorEl={$anchorRefStore.data}
                keepMounted
                open={Boolean($anchorRefStore.data)}
                onClose={() => {
                    $anchorRefStore.setAnchorRef(null);
                }}
            >
                <MenuItem
                    onClick={(event) => {
                        $anchorRefStore.setAnchorRef(null);
                        handleEditCompletedWork();
                    }}
                >
                    Изменить
                </MenuItem>
            </Menu>
            {$contractsDataStore.editCompletedWork && <EditCompletedWork />}
        </>
    );
});
export default CompletedWorks;
