import { Close } from "@mui/icons-material";
import { Dialog, DialogTitle, Grid, IconButton, Typography, TextField } from "@mui/material";
import { styled } from "@mui/material/styles";
import { ButtonSquare } from "@synapse/frontend-react";
import { observer } from "mobx-react";
import { useCallback, useState } from "react";
import { saveUserRoles } from "../../../api-actions/MembersApi";
import { $membersDataStore } from "../../../store/MembersStore";
import { Role } from "../../../types/member";
import Notification from "../../ui/Notification";
import CustomTooltip from "../../ui/Tooltip";
import AutocompleteMultiple from "../../ui/AutocompleteMultiple";

const StyledDialog = styled(Dialog)({
    height: "auto",
    padding: "15px",
    margin: "0 auto",
});

const EditRoleDialog = observer(() => {
    const { memberData, roleList } = $membersDataStore;

    const [strRoles, setStrRoles] = useState("");
    const [selectedRoles, setSelectedRoles] = useState<[] | Role[]>(memberData.roles ? memberData.roles.items : []);

    const handleRolesSelect = useCallback((roles: Role[]) => {
        const strOfGuid = roles.map((item) => item.guid).join(",");
        setStrRoles(strOfGuid);
        setSelectedRoles(roles);
    }, []);

    const handleSubmit = async () => {
        $membersDataStore.setLoading(true);

        const roleInfo = {
            user: memberData.guid,
            role: strRoles,
        };
        await saveUserRoles(roleInfo).then((resp) => {
            $membersDataStore.setOnEditRoleDialog(false);
            Notification({
                title: "Роль сохранена",
                type: "success",
                duration: 30000,
            });
        });
        $membersDataStore.setLoading(false);
        $membersDataStore.updateData();
    };

    return (
        <StyledDialog
            open={$membersDataStore.onEditRoleDialog}
            PaperProps={{
                sx: {
                    borderRadius: "12px",
                    boxShadow: "0 1px 3px 0 rgb(0 0 0 / 20%)",
                    width: 692,
                    overflowY: "unset",
                },
            }}
            keepMounted={true}
            aria-labelledby="dialog-title"
            aria-describedby="dialog-description"
            maxWidth={false}
            scroll="body"
        >
            <IconButton
                aria-label="close"
                style={{
                    position: "absolute",
                    right: "-45px",
                    top: "-45px",
                    color: "white",
                }}
                onClick={() => {
                    $membersDataStore.setOnEditRoleDialog(false);
                }}
            >
                <Close fontSize="large" />
            </IconButton>
            <Grid container justifyContent={"center"}>
                <Grid item xs={9}>
                    <Grid container direction={"column"} justifyContent={"center"} spacing={2}>
                        <Grid item xs={7}>
                            <DialogTitle
                                style={{
                                    textAlign: "center",
                                }}
                            >
                                <Grid container justifyContent={"center"} spacing={2}>
                                    <Grid item>
                                        <Typography
                                            variant={"h1"}
                                            style={{
                                                fontSize: "34px",
                                                lineHeight: "36px",
                                                letterSpacing: "0",
                                                fontWeight: "600",
                                                color: "#20a0ff",
                                                textWrap: "nowrap",
                                            }}
                                            component={"span"}
                                        >
                                            Редактирование прав
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant={"subtitle2"}>{memberData.name}</Typography>
                                    </Grid>
                                </Grid>
                            </DialogTitle>
                        </Grid>
                        <form
                            noValidate
                            style={{
                                paddingLeft: "18px",
                                maxWidth: "100%",
                            }}
                        >
                            <Grid item xs={12} style={{ marginBottom: "19px" }}>
                                <Grid
                                    container
                                    direction={"row"}
                                    alignItems={"center"}
                                    spacing={2}
                                    justifyContent={"space-between"}
                                >
                                    <Grid item xs={11}>
                                        <AutocompleteMultiple
                                            noOptionsText={"Роль не найдена"}
                                            id="role"
                                            value={selectedRoles}
                                            options={roleList}
                                            getOptionLabel={(option: any) => option.name}
                                            label="Роль"
                                            isOptionEqualToValue={(option: any, value: any) =>
                                                option.guid === value.guid
                                            }
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    variant="outlined"
                                                    placeholder="Выберите роль"
                                                    label="Роль"
                                                    required
                                                />
                                            )}
                                            onChange={(e, v) => {
                                                handleRolesSelect(v as Role[]);
                                            }}
                                            sx={{
                                                "& .MuiFormHelperText-root": {
                                                    margin: "3px 0 0",
                                                },
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={1}>
                                        <CustomTooltip tooltip={""} />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </form>
                        <Grid item xs={12} style={{ paddingTop: 0, paddingLeft: 0 }}>
                            <ButtonSquare
                                variant="contained"
                                color="primary"
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    margin: "15px auto",
                                }}
                                onClick={handleSubmit}
                            >
                                Сохранить
                            </ButtonSquare>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </StyledDialog>
    );
});
export default EditRoleDialog;
