import { Grid, Menu, MenuItem, Paper, TableContainer, Typography, IconButton } from "@mui/material";
import { AddButton, SearchField } from "@synapse/frontend-react";
import { observer } from "mobx-react";
import { useCallback, useEffect, useState } from "react";
import { editStatusContractUser } from "../../../../../api-actions/ContractsApi";
import { $anchorRefStore } from "../../../../../store/AnchorRef";
import { $contractsDataStore } from "../../../../../store/ContractsStore";
import { $loginUserStore } from "../../../../../store/ResponseData";
import { contractMember } from "../../../../../types/contractMember";
import { DEFAULT_CONTRACT_MEMBER } from "../../../../../utils/const";
import ConfirmDialog from "../../../../ui/dialog/ConfirmDialog";
import AddUser from "./AddUser";
import dayjs from "dayjs";
import Table from "../../../../ui/table/Table";
import { type MRT_ColumnDef } from "material-react-table";
import { formatDate } from "../../../../../utils/utils";
import { ContractRelationStatus } from "../../../../../utils/const";
import { MoreHoriz } from "@mui/icons-material";
import DatePicker from "../../../../ui/DatePicker";

const ContractCardMembers = observer(() => {
    const [error, setError] = useState("");
    const [loading, setLoading] = useState<boolean>(false);
    const [date, setDate] = useState("");
    const [submitIsDisable, setSubmitIsDisable] = useState(true);

    const {
        contractMembersPage,
        contractMembersRowsPerPage,
        contractMembersSort,
        contractDetail,
        contractMembersSearch,
        memberEditData,
        contractMembersLoadingTable,
        contractMembersList,
    } = $contractsDataStore;

    const getEnv = () => {
        if (memberEditData.status === "WORK") {
            return {
                title: "Архивировать сотрудника",
                menu: "Архивировать",
                submitText: "Архивировать",
                changeStatus: "EXCLUDED",
                message: "отправить в архив",
                pickerlabel: "Дата завершения",
                isWork: true,
                changeKey: "end",
            };
        } else {
            return {
                title: "Вернуть из архива",
                menu: "Вернуть из архива",
                submitText: "Вернуть",
                changeStatus: "WORK",
                message: "вернуть из архива",
                pickerlabel: "Дата начала",
                isWork: false,
                changeKey: "start",
            };
        }
    };

    useEffect(() => {
        $contractsDataStore.getMembersList(
            String(contractDetail?.guid),
            String(contractMembersPage + 1),
            contractMembersSort.by,
            contractMembersSort.order,
            String(contractMembersRowsPerPage),
            contractMembersSearch
        );
    }, [
        contractMembersPage,
        contractMembersRowsPerPage,
        contractMembersSort.by,
        contractMembersSort.order,
        contractDetail?.guid,
        contractMembersSearch,
    ]);

    const columns: MRT_ColumnDef<contractMember>[] = [
        {
            accessorKey: "id",
            header: "№ п/п",
            size: 100,
            enableSorting: false,
            Cell: ({ row }) => <div>{contractMembersRowsPerPage * contractMembersPage + row.index + 1}</div>,
        },
        {
            accessorKey: "name",
            header: "ФИО сотрудника",
            size: 300,
            enableSorting: false,
        },
        {
            id: "start",
            accessorFn: (row) => formatDate(row.start, "d.m.y"),
            header: "Дата трудоустройства",
            size: 150,
            enableSorting: false,
        },
        {
            id: "end",
            accessorFn: (row) => formatDate(row.end, "d.m.y"),
            header: "Дата увольнения",
            size: 150,
            enableSorting: false,
        },
        {
            accessorKey: "status",
            header: "Статус",
            accessorFn: (row) =>
                row?.status ? ContractRelationStatus[row?.status as keyof typeof ContractRelationStatus] : null,
            size: 150,
            enableSorting: false,
        },
        {
            accessorKey: "action",
            header: "Действия",
            size: 100,
            enableSorting: false,
            accessorFn: (row) => (
                <IconButton
                    onClick={(event) => {
                        $anchorRefStore.setAnchorRef(event.currentTarget);
                        $contractsDataStore.setMemberEditData(row);
                    }}
                >
                    <MoreHoriz />
                </IconButton>
            ),
            enableHiding: $loginUserStore.isAdmin(),
        },
    ];
    const changeTableSort = useCallback(
        (key: string) => {
            if (key !== "status" && key !== "action") {
                $contractsDataStore.setMembersSort({
                    by: key,
                    order: contractMembersSort.order === "asc" ? "desc" : "asc",
                });
            }
        },
        [contractMembersSort]
    );

    const handleChangePage = (v: number) => {
        $contractsDataStore.setMembersPage(v);
    };

    const handleChangeRowsPerPage = (v: number) => {
        $contractsDataStore.setMembersRowsPerPage(v);
        $contractsDataStore.setMembersPage(0);
    };

    const handleAddUser = () => {
        $contractsDataStore.setAddNewUser(true);
    };

    const handleChangeStatus = async (form: contractMember, status: string) => {
        let textError = "";
        if (getEnv().isWork && !memberEditData.end) {
            textError = "Дата завершения обязательна!";
        }

        if (!getEnv().isWork && !memberEditData.start) {
            textError = "Дата начала обязательна!";
        }

        if (!date) {
            textError = "Дата обязательна!";
        }

        if (textError) {
            setError(textError);
            setSubmitIsDisable(true);
            return;
        }

        setLoading(true);
        (form as any).status = status;
        $contractsDataStore.setLoading(true);
        await editStatusContractUser(form).then((contract) => {
            $contractsDataStore.setMemberEdit(false);
            if ($loginUserStore.data) {
                $contractsDataStore.getMembersList(
                    String(contractDetail?.guid),
                    String(contractMembersPage + 1),
                    contractMembersSort.by,
                    contractMembersSort.order,
                    String(contractMembersRowsPerPage),
                    contractMembersSearch
                );
                $contractsDataStore.setAddNewUser(false);
            }
        });
        $contractsDataStore.setMembersLoading(false);
        setLoading(false);
    };

    // нормализуем даты для корректного сравнения, у одних дат есть время, у других только дата, приводим к единому формату
    const normalizeDate = (dateString: string) => {
        const normDate = dayjs(dateString).format("YYYY-MM-DD");
        return new Date(normDate).getTime();
    };

    useEffect(() => {
        let textError = "";

        if (contractDetail?.start && date) {
            const contractDateStart = normalizeDate(contractDetail?.start);
            const currentdate = normalizeDate(date);

            if (contractDateStart > currentdate) {
                textError =
                    "Дата не может быть раньше даты начала действия договора (" +
                    dayjs(contractDetail?.start).format("DD.MM.YYYY") +
                    ")";
            }
        }

        if (contractDetail?.end && date) {
            const contractDateEnd = normalizeDate(contractDetail?.end);
            const currentdate = normalizeDate(date);

            if (contractDateEnd < currentdate) {
                textError =
                    "Дата не может быть позже даты окончания действия договора (" +
                    dayjs(contractDetail?.end).format("DD.MM.YYYY") +
                    ")";
            }
        }

        if (getEnv().isWork && memberEditData.start && date) {
            const start = normalizeDate(memberEditData.start);
            const currentdate = normalizeDate(date);

            if (start > currentdate) {
                textError =
                    "Дата архивирования не может быть меньше даты начала прикрепления сотрудника (" +
                    dayjs(memberEditData.start).format("DD.MM.YYYY") +
                    ")";
            }
        }

        if (!getEnv().isWork && memberEditData.end && date) {
            const end = normalizeDate(memberEditData.end);
            const currentdate = normalizeDate(date);

            if (end > currentdate) {
                textError =
                    "Дата поднятия из архива не может быть меньше даты завершения прикрепления сотрудника (" +
                    dayjs(memberEditData.end).format("DD.MM.YYYY") +
                    ")";
            }
        }

        setError(textError);
        if (textError || !date) {
            setSubmitIsDisable(true);
        } else {
            setSubmitIsDisable(false);
        }
    }, [date]);

    return (
        <>
            <Grid container justifyContent={$loginUserStore.isAdmin() ? "space-between" : "flex-end"}>
                {$loginUserStore.isAdmin() ? (
                    <AddButton
                        onClick={handleAddUser}
                        disabled={contractDetail?.available_users?.items.length === undefined}
                        style={{
                            margin: 0,
                        }}
                    />
                ) : null}
                <SearchField
                    value={contractMembersSearch}
                    callback={(value) => $contractsDataStore.setMembersSearch(value)}
                    callbackTimeout={700}
                    style={{
                        width: 300,
                    }}
                />
            </Grid>
            <TableContainer
                component={Paper}
                style={{
                    margin: "15px 0 0",
                }}
            >
                <Table
                    data={contractMembersList?.items}
                    columns={columns}
                    loading={contractMembersLoadingTable}
                    enableExpanding={false}
                    sortProps={contractMembersSort}
                    pageIndex={contractMembersPage}
                    pageSize={contractMembersRowsPerPage}
                    changeTableSort={changeTableSort}
                    handleChangePage={handleChangePage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                    rowCount={contractMembersList?.pagination.total! ?? 0}
                />
            </TableContainer>
            <Menu
                disableScrollLock={true}
                anchorEl={$anchorRefStore.data}
                //keepMounted
                open={Boolean($anchorRefStore.data)}
                onClose={() => {
                    $anchorRefStore.setAnchorRef(null);
                    $contractsDataStore.setMemberEditData(DEFAULT_CONTRACT_MEMBER);
                }}
            >
                <MenuItem
                    onClick={(event) => {
                        $anchorRefStore.setAnchorRef(null);
                        $contractsDataStore.setMemberEdit(true);
                    }}
                >
                    {getEnv().menu}
                </MenuItem>
            </Menu>
            <ConfirmDialog
                display={$contractsDataStore.memberEdit}
                title={getEnv().title}
                submitText={getEnv().submitText}
                onClose={(event) => $contractsDataStore.setMemberEdit(false)}
                onSubmit={(event) => {
                    handleChangeStatus($contractsDataStore?.memberEditData, getEnv().changeStatus);
                }}
                loading={loading}
                submitIsDisable={submitIsDisable}
            >
                <Grid container spacing={3} justifyContent={"center"} mb={3}>
                    <Grid item>
                        <Typography variant="subtitle1">
                            Вы уверены, что хотите {getEnv().message} сотрудника{" "}
                            <b>{$contractsDataStore?.memberEditData?.name}</b> для текущего договора?
                        </Typography>
                    </Grid>
                    <Grid item>
                        <DatePicker
                            value={date ? dayjs(date) : null}
                            label={getEnv().pickerlabel}
                            slotProps={{
                                textField: {
                                    clearable: true,
                                    error: !!error,
                                    helperText: error,
                                    sx: {
                                        width: "208px",
                                    },
                                },
                            }}
                            onChange={(value) => {
                                const newDate =
                                    value && dayjs(value).isValid() ? dayjs(value).format("YYYY-MM-DD") : "";
                                setDate(newDate);
                                $contractsDataStore.setMemberEditData({
                                    ...memberEditData,
                                    [getEnv().changeKey]: newDate,
                                });
                            }}
                        />
                    </Grid>
                </Grid>
            </ConfirmDialog>
            {$contractsDataStore.addNewUser && <AddUser />}
        </>
    );
});
export default ContractCardMembers;
