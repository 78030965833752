import { styled } from "@mui/material/styles";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker as MuiDatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import "dayjs/locale/ru";
import { DateProps } from "../../types/ui/datePicker";

const StyledDatePicker = styled(MuiDatePicker)({
    fontWeight: 600,
    lineHeight: "22px",
    letterSpacing: "0.5px",
    fontSize: "16px",
    color: "#1d1d1b",    
    borderRadius: 12,
    "& .MuiOutlinedInput-root": {
        "& fieldset": {
            borderColor: "#5ac8fa",
            borderRadius: "12px",
        },
        "&:hover fieldset": {
            borderColor: "#20a0ff",
        },
        "&.Mui-focused fieldset": {
            borderColor: "#20a0ff",
        },
    },
});

const DatePicker = (props: DateProps) => {
    const minDate = dayjs(props.minDate ?? "1901-01-01");
    const maxDate = dayjs(props.maxDate ?? "2100-01-01");
    const timezone = props.timezone ?? "system";
    const format = props.format ?? "DD.MM.YYYY";
    const views = props.views ?? ["year", "month", "day"];
    const disabled = props.disabled ?? false;
    const value = props.value ?? null;
    const defaultValue = props.defaultValue ?? null;
    const label = props.label ?? "";
    const onChange = !!props.onChange ? props.onChange : (e: any, v: any) => {};
    const slotProps = !!props.slotProps ? props.slotProps : {};

    return (
        <LocalizationProvider adapterLocale="ru" dateAdapter={AdapterDayjs}>
            <StyledDatePicker
                value={value}
                defaultValue={defaultValue}
                label={label}
                disabled={disabled}
                timezone={timezone}
                views={views}
                format={format}
                minDate={minDate}
                maxDate={maxDate}
                slotProps={slotProps}
                onChange={onChange}
            />
        </LocalizationProvider>
    );
};

export default DatePicker;
