import { observer } from "mobx-react";
import { useCallback, useMemo } from "react";
import { $zoneTableDataStore } from "../../../store/ZoneTableStore";
import Table from "../../ui/table/Table";
import { type MRT_ColumnDef } from "material-react-table";
import { getUKName } from "../../../utils/utils";
import { Zone } from "../../../types/zoneList";
import { generatePath, useNavigate } from "react-router-dom";
import { AppRoute } from "../../../utils/const";

const ZoneTable = observer(() => {
    let navigate = useNavigate();

    const columns = useMemo<MRT_ColumnDef<Zone>[]>(
        () => [
            {
                accessorKey: "id",
                header: "ID",
                size: 328,
            },
            {
                accessorKey: "name",
                id: "address",
                header: "Адрес",
                size: 350,
            },
            {
                accessorFn: (row) => getUKName(row.uk),
                id: "uk_id",
                header: "УК",
                size: 350,
            },
            {
                accessorKey: "description",
                header: "Описание",
                size: 221,
                enableSorting: false,
            },
        ],
        []
    );

    const { page, rowsPerPage, loadingZoneTable, sort, tableLinedata } = $zoneTableDataStore;

    const changeTableSort = useCallback(
        (key: string) => {
            $zoneTableDataStore.setSort({
                by: key,
                order: sort.by !== key ? "asc" : sort.order === "asc" ? "desc" : "asc",
            });
        },
        [sort]
    );

    const handleChangePage = (v: number) => {
        $zoneTableDataStore.setPage(v);
    };

    const handleChangeRowsPerPage = (v: number) => {
        $zoneTableDataStore.setRowsPerPage(v);
        $zoneTableDataStore.setPage(0);
    };

    const handleClickRow = (row: Zone) => {
        $zoneTableDataStore.setShowZoneDetailes(true);
        $zoneTableDataStore.setSelectedZoneData(row);
        $zoneTableDataStore.setNewCoordinates(undefined);
    };
    const handleDoubleClickRow = useCallback(
        (data: Zone) => {
            navigate(
                generatePath(AppRoute.ZoneDetail, {
                    guid: String(data.guid),
                })
            );
        },
        [navigate]
    );

    return (
        <Table
            data={tableLinedata?.items}
            columns={columns}
            loading={loadingZoneTable}
            enableExpanding={false}
            sortProps={sort}
            pageIndex={page}
            pageSize={rowsPerPage}
            changeTableSort={changeTableSort}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            rowCount={tableLinedata?.pagination.total! ?? 0}
            handleClickRow={handleClickRow}
            handleDoubleClickRow={handleDoubleClickRow}
        />
    );
});
export default ZoneTable;
