import { CheckBox, CheckBoxOutlineBlank } from "@mui/icons-material";
import { Autocomplete as AutocompleteMUI, Checkbox, Chip, TextField } from "@mui/material";
import { PropsMultiple } from "../../types/ui/autocomplete";

const AutocompleteMultiple = (props: PropsMultiple) => {
    const disabled = props.disabled ?? false;
    const noOptionsText = props.noOptionsText ?? "";
    const id = props.id ?? "";
    const options = props.options ?? [];
    const value = props.value ?? "";
    const label = props.label ?? "";
    const getOptionLabel = !!props.getOptionLabel ? props.getOptionLabel : (option: any) => option.name ?? option.label;
    const onChange = !!props.onChange ? props.onChange : (e: any, v: any) => {};
    const isOptionEqualToValue = !!props.isOptionEqualToValue
        ? props.isOptionEqualToValue
        : (option: any, value: any) => option.id === value.id;
    const renderInput = !!props.renderInput
        ? props.renderInput
        : (params: any) => <TextField {...params} variant="outlined" label={label} />;
    const sx = !!props.sx ? props.sx : {};

    return (
        <AutocompleteMUI
            multiple
            disabled={disabled}
            noOptionsText={noOptionsText}
            id={id}
            options={options}
            value={value}
            getOptionLabel={getOptionLabel}
            isOptionEqualToValue={isOptionEqualToValue}
            renderOption={(props, option: any, { selected }) => (
                <li {...props}>
                    <Checkbox
                        icon={<CheckBoxOutlineBlank fontSize="small" />}
                        checkedIcon={<CheckBox fontSize="small" />}
                        style={{ marginRight: 8 }}
                        checked={selected}
                    />
                    {option.name ?? option.label}
                </li>
            )}
            renderTags={(value, getTagProps) =>
                value.map((option: any, index) => {
                    return (
                        <Chip
                            variant="outlined"
                            label={option.name ?? option.label}
                            size="small"
                            {...getTagProps({ index })}
                        />
                    );
                })
            }
            onChange={onChange}
            renderInput={renderInput}
            sx={sx}
        />
    );
};

export default AutocompleteMultiple;
