import { makeAutoObservable } from "mobx";
import { DEFAULT_ROWS_PER_PAGE, DEFAULT_MEMBER } from "../utils/const";
import { OrderItemType } from "../types/order.sort";
import { Member, Members, Role } from "../types/member";
import { getMembers, getRoleList } from "../api-actions/MembersApi";
import throttle from "lodash.throttle";

class MembersStore {
    membersData?: Members;
    membersPage: number = 0;
    membersRowsPerPage: number = DEFAULT_ROWS_PER_PAGE;
    membersloadingTable: boolean = false;
    membersSort: OrderItemType = {
        by: "name",
        order: "asc",
    };
    membersSearch: string = "";
    selectedMember: Member | undefined;
    organisation: string = "";
    attach: boolean = false;
    detach: boolean = false;
    emptyMembers: Members = {
        pagination: {
            per_page: DEFAULT_ROWS_PER_PAGE,
            total: 0,
            last_page: 0,
            to: 0,
            current_page: 0,
            from: 0,
        },
        items: [],
    };
    onEditRoleDialog: boolean = false;
    onAttachUkDialog: boolean = false;
    memberData: Member = DEFAULT_MEMBER;
    roleList: Role[] = [];

    constructor() {
        makeAutoObservable(this);
    }

    setPage(page: number) {
        this.membersPage = page;
    }

    setRowsPerPage(rowsPerPage: number) {
        this.membersRowsPerPage = rowsPerPage;
    }

    setLoading(isLoading: boolean) {
        this.membersloadingTable = isLoading;
    }

    setSort(sort: OrderItemType) {
        this.membersSort = sort;
    }

    setData(data: Members) {
        this.membersData = data;
    }

    setAttach(status: boolean) {
        this.attach = status;
    }

    setDetach(status: boolean) {
        this.detach = status;
    }

    setSearch(str: string) {
        this.membersPage = 0;
        this.membersSearch = str;
    }

    getData(
        page: string,
        orderBy: string,
        direction: string,
        pageSize: string,
        membersSearch: string,
        organisation: string
    ) {
        this.setLoading(true);
        this.setData(this.emptyMembers);
        const getMembersList = async () => {
            return await getMembers(page, orderBy, direction, pageSize, membersSearch, organisation);
        };
        const throttledGetMembersList = throttle(getMembersList, 100);
        throttledGetMembersList()
            ?.then((res) => {
                if (res) {
                    this.setData(res);
                }
            })
            .finally(() => this.setLoading(false));
    }

    updateData() {
        this.getData(
            String(this.membersPage + 1),
            this.membersSort.by,
            this.membersSort.order,
            String(this.membersRowsPerPage),
            this.membersSearch,
            this.organisation
        );
    }

    setSelected(data: Member) {
        this.selectedMember = data;
    }

    setOnEditRoleDialog(status: boolean) {
        this.onEditRoleDialog = status;
    }

    setOnAttachUkDialog(status: boolean) {
        this.onAttachUkDialog = status;
    }

    setMemberData(data: Member) {
        this.memberData = data;
    }

    setRoleList(data: Role[]) {
        this.roleList = data;
    }

    getRoleList() {
        const getRoles = async () => {
            return await getRoleList();
        };
        const throttledGetRoles = throttle(getRoles, 100);
        throttledGetRoles()?.then((res) => {
            if (res) {
                this.setRoleList(res);
            }
        });
    }
}

export const $membersDataStore = new MembersStore();
