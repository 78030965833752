const OnCollapseMenu = () => (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M20.8 8.8L15.2 8.8M15.2 8.8V3.2M15.2 8.8L21.6 2.4M8.79999 20.8L8.79999 15.2M8.79999 15.2L3.19999 15.2M8.79999 15.2L2.39999 21.6"
            stroke="#20A0FF"
        />
    </svg>
);

export default OnCollapseMenu;
