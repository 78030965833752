import { makeAutoObservable } from "mobx";
import { LoginResponseData } from "../types/response.data";

class LoginUserStore {
    data?: LoginResponseData | undefined;
    token?: string | undefined;
    administrator: boolean = false;
    ukWorker: boolean = false;
    poWorker: boolean = false;
    headRegion: boolean = false;
    isLogout: boolean = false;

    constructor() {
        makeAutoObservable(this);
    }

    setData(data: LoginResponseData | undefined) {
        this.data = data;

        if (data !== undefined) {
            this.setToken(data.token.token_type + " " + data.token.access_token);
        }

        if (data?.roles.items.length === undefined) {
            return;
        }
        const adminRoles = data?.roles.items.filter((item) => item.name === "Администратор");
        const ukRoles = data?.roles.items.filter((item) => item.name === "Сотрудник УК");
        const poRoles = data?.roles.items.filter((item) => item.name === "Сотрудник подрядной организации");
        const headRegionRoles = data?.roles.items.filter((item) => item.name === "Руководитель региона");

        if (adminRoles.length > 0) {
            this.setAdministrator(true);
        }

        if (ukRoles.length > 0) {
            this.setUkWorker(true);
        }

        if (poRoles.length > 0) {
            this.setPoWorker(true);
        }

        if (headRegionRoles.length > 0) {
            this.setHeadRegion(true);
        }
    }

    setToken(token: string) {
        this.token = token;
    }

    getToken() {
        return this.token;
    }

    setAdministrator(status: boolean) {
        this.administrator = status;
    }

    isAdmin() {
        return this.administrator;
    }

    setUkWorker(status: boolean) {
        this.ukWorker = status;
    }

    isUkWorker() {
        return this.ukWorker;
    }

    setPoWorker(status: boolean) {
        this.poWorker = status;
    }

    isPoWorker() {
        return this.poWorker;
    }

    setHeadRegion(status: boolean) {
        this.headRegion = status;
    }

    isHeadRegion() {
        return this.headRegion;
    }

    getOrganisation() {
        return this.data?.organisation;
    }

    setIsLogout(status: boolean) {
        this.isLogout = status;
    }

    logout() {
        this.setData(undefined);
        this.setToken("");
        localStorage.clear();
        this.setIsLogout(true);
        this.setAdministrator(false);
        this.setUkWorker(false);
        this.setPoWorker(false);
        this.setHeadRegion(false);
    }
}

export const $loginUserStore = new LoginUserStore();
