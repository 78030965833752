import { Autocomplete as AutocompleteMUI, TextField } from "@mui/material";
import { Props } from "../../types/ui/autocomplete";

const Autocomplete = (props: Props) => {
    const disabled = props.disabled ?? false;
    const noOptionsText = props.noOptionsText ?? "";
    const id = props.id ?? "";
    const options = props.options ?? [];
    const value = props.value ?? "";
    const label = props.label ?? "";
    const filterOptions = !!props.filterOptions ? props.filterOptions : undefined;
    const onChange = !!props.onChange ? props.onChange : (e: any, v: any) => {};
    const renderInput = !!props.renderInput
        ? props.renderInput
        : (params: any) => <TextField {...params} variant="outlined" label={label} />;
    const onInputChange = !!props.onInputChange ? props.onInputChange : (e: any, v: any) => {};
    const sx = !!props.sx ? props.sx : {};

    return (
        <AutocompleteMUI
            disabled={disabled}
            noOptionsText={noOptionsText}
            id={id}
            options={options}
            value={value}
            filterOptions={filterOptions}
            renderInput={renderInput}
            onChange={onChange}
            onInputChange={onInputChange}
            getOptionLabel={(option: any) => option.name ?? option.label}
            sx={sx}
        />
    );
};

export default Autocomplete;
