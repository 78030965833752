import { PointExpression } from "leaflet";
import { ContractAddress } from "../types/contractAddress";
import { contractMember } from "../types/contractMember";
import { Zone } from "../types/zoneList";

export enum AppRoute {
    Login = "/login",
    Logout = "/logout",
    Root = "/",
    NewContract = "/menu/:id/contractsList/newContract",
    TimeSheetJournal = "/timesheetJournal",
    TimeSheetEdit = "/timesheet/edit",
    TimeSheetApprove = "/timesheet/approve",
    Monitoring = "/monitoring",
    Monitoringv2 = "/monitoring_v2",
    Monitoringv3 = "/monitoring_v3",
    ZoneList = "/zones",
    ZoneNew = "/zones/new",
    ZoneDetail = "/zones/:guid",
    ContractList = "/contracts",
    ContractDetail = "/contracts/:guid",
    ContractCardAddress = "/contracts/:guid/address",
    ContractCardMembers = "/contracts/:guid/members",
    Members = "/members",
    Devices = "/devices",
}

export enum ContractStatus {
    ARCHIVE = "В архиве",
    ACTIVE = "В работе",
}

export enum ContractRelationStatus {
    EXCLUDED = "В архиве",
    WORK = "В работе",
}

export const DEFAULT_COORDINATES = JSON.parse('{"type":"Point","coordinates":[37.6173, 55.7558]}');
export const DEFAULT_ICONSIZE: PointExpression | undefined = [27, 39];
export const DEFAULT_ICONANCHOR: PointExpression | undefined = [20, 40];
export const DEFAULT_POINT: Zone = {
    id: "",
    description: "",
    guid: "",
    house: "",
    uk: "",
    name: "",
    coordinates: DEFAULT_COORDINATES,
};

export const DEFAULT_CONTRACT = {
    from: "",
    to: "",
    number: "",
    guid: "",
    status: "",
    description: "",
    end: "",
    start: "",
    date: "",
    available_houses: {
        items: [
            {
                id: "",
                name: "",
            },
        ],
    },
    available_users: {
        items: [
            {
                id: "",
                name: "",
            },
        ],
    },
};

export const DEFAULT_CONTRACT_ADDRESS: ContractAddress = {
    guid: "",
    contract: "",
    house: "",
    status: "WORK",
    name: "",
    zones: {
        items: [],
    },
    start: "",
    end: "",
};

export const DEFAULT_CONTRACT_MEMBER: contractMember = {
    guid: "",
    contract: "",
    user: "",
    status: "WORK",
    name: "",
    start: "",
    end: "",
};

export const DEFAULT_ROWS_PER_PAGE = 25;

export const DEFAULT_DEVICE = {
    guid: "",
    imei: "",
    name: "",
    model: "",
    phone: "",
    description: "",
    traccar_id: "",
    attach: {
        items: [],
    },
    can_attach: true,
    can_detach: true,
    min_attach_date: "",
    current_user: "",
    last_online: "",
    status: "",
    status_date: "",
    battery: "",
};

export const DEFAULT_MEMBER = {
    id: 0,
    guid: "",
    login: "",
    name: "",
    organisation: {
        guid: "",
        is_uk: false,
        name: "",
    },
    roles: {
        items: [
            {
                guid: "",
                name: "",
            },
        ],
    },
    linkedUk: {
        items: [
            {
                guid: "",
                is_uk: false,
                name: "",
            },
        ],
    },
};
