import axios from '../axios';
import Notification from '../components/ui/Notification';
import { Authorization } from '../types/authorization';
import { UserData } from '../types/user.data';

export async function loginUser(form: UserData) {
    let data = { login: form.login, password: form.password };
    let config = {
        method: "post",
        url: "/UserService/Auth/login",
        data: data,
    };
    try {
        const response = await axios.request(config);
        const result = response.data;
        if (!result) {
            Notification({
                title: 'Не удалось получить ответ',
                type: 'error',
            });
            return;
        }
        if (result?.error?.status_code === 401) {
            Notification({
                title: 'Не удалось авторизоваться. Проверьте логин и пароль',
                type: 'error',
                duration: 5000
            });
            return;
        }
        if (!result?.data?.roles.items.length) {
            Notification({
                title: 'Для Вашей учетной записи недоступен просмотр сведений, обратитесь к администратору портала',
                type: 'error',
                duration: 5000
            });
            return;
        }
        localStorage.setItem('userData', JSON.stringify(result));
        return result;
    } catch (error) {
        Notification({
            title: 'Не удалось авторизоваться. Проверьте логин и пароль',
            type: 'error',
        });
    }
}

export async function checkToken() {
    let config = {
        method: "get",
        url: "/UserService/Auth/check",
    };
    try {
        const response = await axios.request(config);
        if (!response) return;
        const result: Authorization = response.data;
        if (!result) return;
        return result;
    } catch (error) {
        Notification({
            title: 'Не удалось загрузить данные',
            type: 'error',
        });
    }
}
